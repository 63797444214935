import React from 'react';
import Layout from '../../components/Layout';

// images
import dan from './dan2.png';

export default () => {
  return (
    <Layout>
      <div class="content">
        <main class="about">
          <div class="container">
            <div class="about__image">
              <img src={dan} alt="Dan Brown" width="1126" height="766" />
            </div>
          </div>

          <div class="container">
            <div class="experience-info">
              <h1>Dan Brown</h1>
              <h3>
                Brand &amp; marketing expert. Creator. Product guy. Swiss Army
                Knife.
              </h3>
              <a
                href="https://www.linkedin.com/in/slightlyoffbeat"
                target="_blank"
                rel="noopener noreferrer"
                class="button experience-info__button button--primary"
              >
                LinkedIn
              </a>
            </div>
          </div>

          <div class="single-content">
            <div class="container">
              <p>
                I am a brand, marketing, and product expert with a strategic
                mind and an entrepreneurial spirit. I have a decade&nbsp;of
                experience working with global brands and products.
              </p>
              <p>
                I have worn a lot of hats in my career. I’ve been a marketer,
                strategist, developer, project manager, editorial director,
                content creator, producer, customer support manager and a
                writer.
              </p>
              <p>
                I love to fuse left brain thinking with right brain creativity.
                I am curious by nature. I google everything. My approach to
                success is to blur the lines between brand, product, marketing,
                design, and development.
              </p>
              <p>
                Want to work together? Let's chat: dan (at) danvswild (dot) com.
              </p>
              <p>&nbsp;</p>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
};
