import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <span className="footer__text">Made in sunny San Diego</span>

      <a
        className="footer__icon"
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.twitter.com/slightlyoffbeat"
      >
        <i className="footer__icon fa fa-twitter" aria-hidden="true" />
      </a>

      <a
        className="footer__icon"
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.github.com/slightlyoffbeat"
      >
        <i className="footer__icon fa fa-github" aria-hidden="true" />
      </a>

      <a
        className="footer__icon"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.linkedin.com/in/slightlyoffbeat"
      >
        <i className="footer__icon fa fa-linkedin" aria-hidden="true" />
      </a>

      <a
        className="footer__icon"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/slightlyoffbeat"
      >
        <i className="footer__icon fa fa-instagram" aria-hidden="true" />
      </a>

      {/*
      <a
        className="footer__icon"
        target="_blank"
        rel="noopener noreferrer"
        href="/contact"
      >
        <i className="footer__icon fa fa-envelope-o" aria-hidden="true" />
      </a>
      */}
    </footer>
  );
};

export default Footer;
