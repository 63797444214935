import React from 'react';
import Link from 'gatsby-link';

// images
import logo from '../img/logo.svg';

class Header extends React.Component {
  componentDidMount() {
    // classes
    const mainHeader = document.querySelector('.header');
    const theNav = document.querySelector('.primary-nav');
    const navTrigger = document.querySelector('.hamburger');
    const navOpenClass = 'is-active';
    const headerHiddenClass = 'is-hidden';

    // scrolling variables
    let scrolling = false;
    let previousTop = 0;
    let currentTop = 0;
    const scrollDelta = 10;
    const scrollOffset = 150;

    // will open navigation on mobile
    navTrigger.addEventListener('click', event => {
      event.preventDefault();
      mainHeader.classList.toggle(navOpenClass);
      theNav.classList.toggle(navOpenClass);
    });

    // will monitor scrolling and add or remove is-hidden class
    const onScroll = () => {
      currentTop = window.pageYOffset;
      const checkSimpleNavigation = theCurrentTop => {
        if (previousTop - theCurrentTop > scrollDelta) {
          mainHeader.classList.remove(headerHiddenClass);
        } else if (
          theCurrentTop - previousTop > scrollDelta &&
          theCurrentTop > scrollOffset
        ) {
          mainHeader.classList.add(headerHiddenClass);
        }
      };

      const autoHideHeader = () => {
        checkSimpleNavigation(currentTop);
        previousTop = currentTop;
        scrolling = false;
      };

      if (!scrolling) {
        scrolling = true;
        if (!window.requestAnimationFrame) {
          setTimeout(autoHideHeader, 250);
        } else {
          requestAnimationFrame(autoHideHeader);
        }
      }

      // Change background color of header on scroll
      if (currentTop > 80) {
        mainHeader.classList.add('is-scroll');
      } else {
        mainHeader.classList.remove('is-scroll');
      }
    };

    document.addEventListener('scroll', onScroll, false);
  }
  render() {
    return (
      <header className="header">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>

        <button className="hamburger" type="button">
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>

        <nav className="primary-nav">
          <ul className="primary-nav__list ">
            <li className="primary-nav__item">
              <Link to="/about/" className="primary-nav__link">
                About
              </Link>
            </li>
            {/*}
            <li className="primary-nav__item">
              <Link to="/portfolio" className="primary-nav__link">
                Portfolio
              </Link>
            </li>
            */}
            <li className="primary-nav__item">
              <Link to="/experience/" className="primary-nav__link">
                Experience
              </Link>
            </li>
            {/*<li className="primary-nav__item">
              <Link to="/contact/" className="primary-nav__link">
                Contact
              </Link>
             </li>*/}
          </ul>
        </nav>
      </header>
    );
  }
}

export default Header;
