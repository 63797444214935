import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery } from 'gatsby';
import Header from './header';
import Footer from './Footer';

// styles
import '../style/style.scss';

import screenshot from '../img/screenshot.png';
import faviconico from '../img/favicon.ico';
import faviconpng from '../img/favicon.png';

const Layout = ({ children, footer }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet>
          {/* Favicons */}
          <link rel="shortcut icon" type="image/x-icon" href={faviconico} />
          <link rel="icon" type="image/png" href={faviconpng} sizes="196x196" />
          {/* Meta */}
          <title>Dan vs Wild</title>
          <meta name="description" content="A Modern Tale of Survival" />
          <meta name="keywords" content="Dan, Brown, Web, Beard" />

          {/* Favicons */}
          <link rel="shortcut icon" type="image/x-icon" href={faviconico} />
          <link rel="icon" type="image/png" href={faviconpng} sizes="196x196" />

          {/* Social */}
          <meta property="og:title" content="Dan (Brown) vs Wild" />
          <meta
            property="og:description"
            content="I am a creator, developer, product guy, strategist, homebrewer, runner, sock enthusiast, beard evangelist, writer, drummer, adventurer, Oxford comma advocate, and human Swiss Army Knife."
          />
          <meta property="og:image" content={screenshot} />
          <meta property="og:url" content="http://danvswild.com" />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div>{children}</div>

        {footer === false ? <div /> : <Footer />}
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
